import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { notification } from 'antd';
import { convertToRaw, Modifier, EditorState } from 'draft-js';
import * as _ from 'lodash';

// import download from 'downloadjs';

import { FETCH_OR_CREATE_WIX_PROPOSAL } from 'graphql/queries/proposalQueries';
import { UPDATE_PROPOSAL_CLIENT_MUTATION } from 'graphql/mutations/proposalMutation';
import { USER_DETAILS, USER_TEAMS_DETAILS } from 'graphql/queries/userQueries';
import { cache, getUserId, userVar } from 'graphql/cache';
import helpers, { getLanguageCode } from 'helpers/proposal';
import * as wixHelpers from 'helpers';
import { formatLocale } from 'pages/Proposal/components/RichEditor/wix-components/wix-pricing-helper';
import { PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';
import { USER_UPDATE } from 'graphql/mutations/userMutations';
import { GET_CONFIGURATION } from 'graphql/queries/configurationQueries';
import {
  FETCH_WIX_PRODUCTS_AND_SERVICES_AND_INFO,
  FETCH_MORE_WIX_PRODUCTS_AND_SERVICES,
} from 'graphql/queries/wixQueries';
import { GET_TEMPLATE, GET_SECTION } from 'graphql/queries/contentLibraryQueries';
import { CONTENT_LIBRARY_ADD_ITEM } from 'graphql/mutations/contentLibraryMutations';
import { NoTemplateModal } from './components/Draft/DraftModals';
import { getBlockSelection } from './components/RichEditor/lib/selection';

import Loader from 'components/Loader';
import Draft from './components/Draft';
import Publish from './components/Publish';
import Wizard from './components/Wizard/Wizard';
import EmptyProposal from './components/EmptyProposal';
import ProposalHeaderBar from './components/ProposalHeaderBar';
import ProposalWixHeaderBar from './components/ProposalWixHeaderBar';
import commonUtils from 'utils/utils';
import AppContext from 'context/index';
import useWindowDimensions from 'hooks/useWindowDimensions';
import PublishContent from 'pages/Proposal/components/Publish/components/PublishContent';
import utils from 'utils/utils';
import './Proposal.scss';
import { ProposalContextProvider, useProposalContext } from 'context/proposal';

const ProposalContent = (props) => {
  const {
    user,
    params,
    prop,
    setProp,
    path,
    type,
    propRef,
    template,
    section,
    wixEditor,
    isFetchingProposal,
    proposalSettings,
    setProposalSettings,
    handleUpdateEditing,
    setSection,
    setTemplate,
    // undoableProposalStackRef,
    // setUndoableProposalStack,
    loadFonts,
    subscribeToUpdateProposal,
    templateWixEditor,
    updateTemplate,
    updateLoaderState,
    updateSection,
    saveTemplateProposal,
    updateProposalPreHandler,
  } = useProposalContext();

  const { isOnline } = useContext(AppContext);
  const { isMobile } = useWindowDimensions();

  const [config, setConfig] = useState({});
  const [wixProductsAndServices, setWixProductsAndServices] = useState([]);
  const [wixSiteProperties, setWixSiteProperties] = useState();
  const [usersTeam, setUsersTeam] = useState([]);
  const [loadingWixProductsAndServices, setLoadingWixProductsAndServices] = useState(false);
  const [openVariablesList, setOpenVariablesList] = useState(false);

  const [isSavingForm, setIsSavingForm] = useState(false);
  const [openWixPreview, setOpenWixPreview] = useState(false);
  const [templateNodal, setNoTemplateModal] = useState(false);

  const [networkOnline, setNetworkOnline] = useState(true);

  const clientWixPreview = !!path?.includes('/proposal-preview') || type === 'wix-proposal';
  const templateWixPreview =
    !!path?.includes('/template-preview') || !!(utils.getQueryStringValue('for') === 'wix');

  const isWix = !!prop.wixProposalId?.length;
  const isOwner = utils.getQueryStringValue('owner') === 'true';

  const { loading: isFetchingUser, data: userInfo } = useQuery(USER_DETAILS, {
    variables: {
      id: getUserId(),
      type: window.instanceId ? 'wix-user' : '',
    },
    skip: !getUserId() || user || clientWixPreview || templateWixPreview,
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      const { fetchUser } = res;
      userVar({ ...user, ...fetchUser });
    },
  });

  useQuery(USER_TEAMS_DETAILS, {
    variables: { teamId: user?.teamId || getUserId() },
    skip: !getUserId() || !user || path !== '/pd/:id' || openWixPreview,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ fetchUsers }) => {
      setUsersTeam(fetchUsers);
    },
  });

  const configText = useCallback(
    (x, notmarked, disableTrim) => {
      return helpers.configText({
        x,
        notmarked,
        config,
        prop,
        user,
        vars: {},
        disableTrim,
      });
    },
    [config, prop, user]
  );

  const [addItem] = useMutation(CONTENT_LIBRARY_ADD_ITEM, {
    onCompleted() {
      notification.success({
        description: 'Template saved successfully.',
      });
    },
    onError() {
      notification.error({
        description: 'Template save failed.',
      });
    },
  });

  const { loading: isFetchingTemplate } = useQuery(GET_TEMPLATE, {
    variables: {
      _id: params.tid || params.cid || window.templateId,
      type: templateWixPreview ? 'wix-preview' : '',
      instanceId: templateWixPreview ? window.instanceId : '',
    },
    skip:
      template ||
      (path !== '/t/:tid' && path !== '/pt/:tid' && path !== '/PDF/:cid' && !templateWixPreview) ||
      (path === '/PDF/:cid' && type !== 'template'),
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchTemplate }) => {
      if (fetchTemplate) {
        let templateData = JSON.parse(JSON.stringify(fetchTemplate));

        propRef.current = templateData;
        setTemplate({ ...templateData, pricing: templateData.pricing || { strategy: 'table' } });

        // if (path === '/d/:pid' || path === '/t/:tid' || path === '/s/:sid' || wixEditor) {
        //   const undoStack = {
        //     past: [fetchTemplate.draft],
        //     present: fetchTemplate.draft,
        //     future: [],
        //   };
        //   undoableProposalStackRef.current = undoStack;
        //   setUndoableProposalStack(undoStack);
        // }

        loadFonts(templateData);
        if (templateWixPreview) window?.proposalApi?.onLoaded();
      }
    },
  });

  const { loading: isFetchingSection } = useQuery(GET_SECTION, {
    variables: {
      _id: params.sid,
    },
    skip: template || (path !== '/s/:sid' && path !== '/ps/:sid'),
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchLibrary }) => {
      if (fetchLibrary) {
        const sectionInfo = {
          ...JSON.parse(
            JSON.stringify({
              draft: { [fetchLibrary._id]: fetchLibrary, sectionorder: [fetchLibrary._id] },
            })
          ),
          language: fetchLibrary.language,
          isHeader: fetchLibrary.isHeader,
          sectionStyles: fetchLibrary.sectionStyles,
        };

        propRef.current = sectionInfo;
        setSection(sectionInfo);

        // if (path === '/d/:pid' || path === '/t/:tid' || path === '/s/:sid') {
        //   const undoStack = {
        //     past: [{ draft: { [fetchLibrary._id]: fetchLibrary } }],
        //     present: { draft: { [fetchLibrary._id]: fetchLibrary } },
        //     future: [],
        //   };
        //   undoableProposalStackRef.current = undoStack;
        //   setUndoableProposalStack(undoStack);
        // }

        loadFonts(sectionInfo);
      }
    },
  });

  const { loading: isFetchingOrCreatingWixProposal } = useQuery(FETCH_OR_CREATE_WIX_PROPOSAL, {
    notifyOnNetworkStatusChange: true,
    variables: {
      wixTemplateId: window.wixTemplateId,
      templateId: window.templateId,
      wixProposalId: window.proposalId,
      type: openWixPreview ? 'preview' : clientWixPreview ? 'client' : 'editor',
    },
    skip: (!window.proposalId && !window.templateId) || prop,
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchOrCreateWixProposal }) => {
      if (fetchOrCreateWixProposal) {
        const updatedProposal = JSON.parse(JSON.stringify(fetchOrCreateWixProposal));

        if (!updatedProposal.wixPricing.locale) {
          updatedProposal.wixPricing.locale = {
            language: 'en',
            country: 'US',
          };
        }

        const locale = formatLocale(updatedProposal?.wixPricing);
        updatedProposal.dateFormat = wixHelpers.formatDateByRegion(locale);

        if (updatedProposal.noTemplate) {
          // show modal
          setNoTemplateModal(true);
          delete updatedProposal.noTemplate;
        } else {
          delete updatedProposal.noTemplate;

          if (updatedProposal.dateFormat) {
            setProposalSettings({ dateFormat: updatedProposal.dateFormat });
          }

          handleUpdateEditing({
            ...updatedProposal,
            pricing: updatedProposal.pricing || { strategy: 'table' },
          });

          // if (path === '/d/:pid' || path === '/t/:tid' || path === '/s/:sid' || wixEditor) {
          //   const undoStack = {
          //     past: [fetchOrCreateWixProposal.draft],
          //     present: fetchOrCreateWixProposal.draft,
          //     future: [],
          //   };
          //   undoableProposalStackRef.current = undoStack;
          //   setUndoableProposalStack(undoStack);
          // }

          loadFonts(updatedProposal);
          window?.proposalApi?.onLoaded();
        }
      }
    },
  });

  const [loadConfig, { loading: isFetchingConfiguration }] = useLazyQuery(GET_CONFIGURATION, {
    fetchPolicy: 'cache-and-network',
    skip: config,
    onCompleted: (data) => {
      setConfig(data?.fetchConfiguration || {});
    },
  });

  const [fetchWixProductsAndServicesAndInfo] = useLazyQuery(
    FETCH_WIX_PRODUCTS_AND_SERVICES_AND_INFO,
    {
      fetchPolicy: 'network-only',
      skip:
        (!user && !loadingWixProductsAndServices) ||
        path === '/PDF/:cid' ||
        path === '/pd/:id' ||
        path === '/cd/:cid',
      onCompleted: ({ fetchWixProductsAndServices }) => {
        setWixProductsAndServices(fetchWixProductsAndServices || {});
        if (prop.wixConnectedInstance) {
          const connectedWixServices = fetchWixProductsAndServices?.services?.find(
            (service) => service.instanceId === prop.wixConnectedInstance
          );
          if (connectedWixServices) {
            setWixSiteProperties(
              {
                name: connectedWixServices.name,
                description: connectedWixServices.description,
                logo: connectedWixServices.logo,
                domain: connectedWixServices.domain,
              } || {}
            );
          }
        } else {
          setWixSiteProperties(
            {
              name: '',
              description: '',
              logo: '',
              domain: '',
            } || {}
          );
        }
        setLoadingWixProductsAndServices(false);
      },
      onError: (err) => {
        setLoadingWixProductsAndServices(false);
        console.log(err, 'wix error');
      },
    }
  );

  const [fetchMoreWixProductsAndServices, { loading: fetchMoreWixProductsAndServicesLoading }] =
    useLazyQuery(FETCH_MORE_WIX_PRODUCTS_AND_SERVICES, {
      fetchPolicy: 'network-only',
      onCompleted: ({ fetchMoreWixProductsAndServices }) => {
        let newWixProductsAndServices = { ...wixProductsAndServices };

        if (fetchMoreWixProductsAndServices.type === 'products') {
          if (fetchMoreWixProductsAndServices.instanceId) {
            newWixProductsAndServices['products'] = wixProductsAndServices?.products.map(
              (product) => {
                if (product.instanceId === fetchMoreWixProductsAndServices.instanceId) {
                  return {
                    ...product,
                    fields: [...product.fields, ...fetchMoreWixProductsAndServices.fields],
                  };
                } else {
                  return product;
                }
              }
            );
          } else {
            newWixProductsAndServices.products[0] = {
              ...newWixProductsAndServices.products[0],
              fields: {
                ...wixProductsAndServices.products[0]?.fields,
                ...fetchMoreWixProductsAndServices.fields,
              },
            };
          }
        } else {
          if (fetchMoreWixProductsAndServices.instanceId) {
            newWixProductsAndServices['services'] = wixProductsAndServices?.services.map(
              (service) => {
                if (service.instanceId === fetchMoreWixProductsAndServices.instanceId) {
                  return {
                    ...service,
                    fields: [...service.fields, ...fetchMoreWixProductsAndServices.fields],
                  };
                } else {
                  return service;
                }
              }
            );
          } else {
            newWixProductsAndServices.services[0] = {
              ...newWixProductsAndServices.services[0],
              fields: {
                ...wixProductsAndServices.services[0]?.fields,
                ...fetchMoreWixProductsAndServices.fields,
              },
            };
          }
        }

        setWixProductsAndServices(newWixProductsAndServices || {});
      },
      onError: (err) => {
        setLoadingWixProductsAndServices(false);
        console.log(err, 'wix error');
      },
    });

  useEffect(() => {
    if ((prop || template || section) && !Object.keys(config)?.length) {
      loadConfig({
        variables: {
          language:
            prop?.language?.toLowerCase() ||
            template?.language?.toLowerCase() ||
            section?.language?.toLowerCase() ||
            'english',
        },
      });
    }
  }, [prop, template, section, config, loadConfig]);

  useEffect(() => {
    if (
      (prop || template || section) &&
      user?.wix?.length &&
      !Object.keys(wixProductsAndServices)?.length
    ) {
      setLoadingWixProductsAndServices(true);
      if (
        !loadingWixProductsAndServices &&
        path !== '/PDF/:cid' &&
        path !== '/pd/:id' &&
        path !== '/cd/:cid' &&
        !wixEditor &&
        !templateWixEditor &&
        !templateWixPreview &&
        !clientWixPreview
      ) {
        fetchWixProductsAndServicesAndInfo({
          variables: {
            instanceId: prop?.wixConnectedInstance,
          },
        });
      }
    }
  }, [prop, template, section, wixProductsAndServices, fetchWixProductsAndServicesAndInfo, user]); //eslint-disable-line

  useEffect(() => {
    if (!isOnline && networkOnline) {
      setNetworkOnline(false);
    } else {
      if (!networkOnline && isOnline && prop) {
        const unsavedProposal = localStorage.getItem('unsavedproposal');

        if (unsavedProposal) {
          const proposal = JSON.parse(unsavedProposal);
          localStorage.setItem('unsavedproposal', '');

          if (proposal.templateName) {
            updateTemplate({
              variables: {
                topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
                  proposal?.channel || proposal?.auid || proposal?.uid
                }`,
                updateType: 'edit',
                template: { ...proposal, id: proposal._id },
              },
            });
          } else if (proposal.sectionId) {
            updateSection({
              variables: {
                topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
                  proposal?.channel || proposal?.auid || proposal?.uid
                }`,
                updateType: 'edit',
                section: {
                  ...proposal.draft[proposal.sectionId],
                  thumbnail: '',
                },
              },
            });
          } else {
            setProp(proposal);

            updateProposalPreHandler(
              {
                variables: {
                  topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
                    proposal?.channel || proposal?.auid || proposal?.uid
                  }`,
                  updateType: 'edit',
                  proposal: {
                    ...proposal,
                    id: proposal._id,
                  },
                },
              },
              proposal
            );
          }
        }
        setNetworkOnline(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, prop]);

  const [userway, setUserway] = useState({
    language: '',
    position: 0,
  });

  useEffect(() => {
    if (prop._id && (path === '/pd/:id' || path === '/cd/:cid')) {
      let proposalHasUserway = prop.hasOwnProperty('userway') && prop.userway !== null;

      // if /pd then use user.userway
      const creatorUserway = (path === '/pd/:id' ? user?.userway : prop?.creatorUserway) === true;

      // disabled for this proposal
      if (
        (proposalHasUserway && prop.userway === true) ||
        (!proposalHasUserway && creatorUserway)
      ) {
        const widgetAddedToDom = !!document.getElementById('userway-script');
        const isRtl = utils.isRTLProposal(prop);
        const languageCode = getLanguageCode(prop.language?.toLowerCase());
        const position = userwayPosition(isRtl);

        const newUserway = {};

        if (userway.language !== languageCode) {
          newUserway.language = languageCode;
        }
        if (userway.position !== position) {
          newUserway.position = position;
        }

        if (widgetAddedToDom) {
          let userwayDom = document.getElementsByClassName('uwy');
          if (userwayDom?.length > 0) {
            try {
              window.UserWay.iconVisibilityOn();

              if (userway.language !== languageCode) {
                window.UserWay.changeWidgetLanguage(languageCode);
              }
            } catch (e) {}

            // icon position
            if (userway.position !== position) {
              [userwayDom] = userwayDom;
              const activeClass = `userway_p${position}`;
              const inactiveClass = ['userway_p1', 'userway_p2', 'userway_p6', 'userway_p7'].filter(
                (f) => f !== activeClass
              );

              userwayDom.classList.remove(...inactiveClass);
              userwayDom.classList.add(activeClass);
            }
          }
        } else {
          const script = document.createElement('script');
          script.setAttribute('src', 'https://cdn.userway.org/widget.js');
          script.setAttribute('data-account', 'YQuDIqtDQg');
          script.setAttribute('id', 'userway-script');
          script.setAttribute('data-position', position);
          script.setAttribute('data-language', languageCode);
          document.body.appendChild(script);
        }

        if (Object.keys(newUserway).length > 0) {
          setUserway({ ...userway, ...newUserway });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop.userway, path, isMobile]);

  const userwayPosition = (isRtl) => {
    // 7(top left), 1(top right) - mobile,  HE, EN
    if (isMobile) {
      return isRtl ? 7 : 1;
    }
    // 2(right middle), 6(left middle) - desktop, HE, EN
    return isRtl ? 2 : 6;
  };

  const [saveUser] = useMutation(USER_UPDATE, {
    async onCompleted(data) {
      cache.evict('fetchUser');
      userVar({ ...user, ...data.updateUser });
    },
  });

  const [updateProposalClient] = useMutation(UPDATE_PROPOSAL_CLIENT_MUTATION, {
    onCompleted: ({ updateProposalClient }) => {
      propRef.current = updateProposalClient;
      setProp(updateProposalClient);
    },
  });

  const fieldChanged = (fname, raw, name, fieldChangedSuccess = () => {}) => {
    if (prop) {
      prop.editEvent = true;
    }
    localStorage.setItem('editEvent', true);

    if (name === 'header') {
      localStorage.setItem('headerChangeEvent', true);
    }

    const tempProp = prop || template || section;
    if (tempProp?.draft?.[name]?.image && _.isEqual(prop?.draft?.[name]?.raw, raw)) {
      updateLoaderState(true);
    }
    const templateProposalSectionInput = { [`draft.${name}.${fname}`]: raw };

    if (name === 'header' && fname === 'rawtitle' && path === '/t/:tid') {
      templateProposalSectionInput['templateName'] = raw?.blocks[0]?.text;
    }
    saveTemplateProposal(templateProposalSectionInput, fieldChangedSuccess);
  };

  const saveTemplateProposalSectionDraft = (name, fname) => {
    return (es, saveTemplateProposalSectionDraftSuccess = () => {}) => {
      let raw = convertToRaw(es.getCurrentContent());

      if (!_.isEqual(prop?.draft?.[name]?.[fname], raw)) {
        try {
          // Remove AI highlight if it exists
          let selectionState = es.getSelection();
          let newEditorState = es;

          if (selectionState && !selectionState.isCollapsed()) {
            const contentState = es.getCurrentContent();
            const blocks = contentState.getBlockMap();
            let newContentState = contentState;

            blocks.forEach((block) => {
              // remove HIGHLIGHT from the entire block
              newContentState = Modifier.removeInlineStyle(
                newContentState,
                getBlockSelection(block),
                'HIGHLIGHT-AI'
              );
            });

            newEditorState = EditorState.push(es, newContentState, 'change-inline-style');
            raw = convertToRaw(newEditorState.getCurrentContent());
          }
        } catch (error) {
          console.log('No Style');
        }
        fieldChanged(fname, raw, name, saveTemplateProposalSectionDraftSuccess);
      } else {
        saveTemplateProposalSectionDraftSuccess && saveTemplateProposalSectionDraftSuccess(false);
      }
    };
  };

  const handleFetchWixProductsAndServicesAndInfo = (value) => {
    setLoadingWixProductsAndServices(true);
    if (!loadingWixProductsAndServices) {
      fetchWixProductsAndServicesAndInfo(value);
    }
  };

  // we have pid or _id but didnt get the item, waiting for server publish, empty screen
  if (
    isFetchingProposal ||
    isFetchingUser ||
    isFetchingConfiguration ||
    isFetchingTemplate ||
    isFetchingSection ||
    isFetchingOrCreatingWixProposal ||
    (prop &&
      !Object.keys(config)?.length &&
      (params.pid || params.id || params.cid || window.proposalId))
  ) {
    return <Loader />;
  }

  if (!prop && !template && !section) {
    if (!params.pid && !params.id && !params.tid && !params.sid) {
      <>
        {templateNodal && (
          <NoTemplateModal wixEditor={wixEditor} templateWixEditor={templateWixEditor} />
        )}
        <ProposalHeaderBar />
        <EmptyProposal />
      </>;
    }
    if (wixEditor || templateWixEditor) {
      return (
        <>
          {templateNodal && (
            <NoTemplateModal wixEditor={wixEditor} templateWixEditor={templateWixEditor} />
          )}
          <ProposalWixHeaderBar status="empty" />
          <EmptyProposal />
        </>
      );
    }
    return (
      <>
        {templateNodal && (
          <NoTemplateModal wixEditor={wixEditor} templateWixEditor={templateWixEditor} />
        )}
        <ProposalHeaderBar />
        <EmptyProposal />
      </>
    );
  }

  if (
    path === '/d/:pid' ||
    path === '/t/:tid' ||
    path === '/s/:sid' ||
    ((wixEditor || templateWixEditor) && !openWixPreview)
  ) {
    return (
      <Draft
        user={user || userInfo?.fetchUser}
        saveProposalDraft={saveTemplateProposalSectionDraft}
        config={config}
        openVariablesList={openVariablesList}
        setOpenVariablesList={setOpenVariablesList}
        saveUser={saveUser}
        isTemplate={path === '/t/:tid' ? true : false}
        isSection={path === '/s/:sid' ? true : false}
        isHeader={
          path === '/s/:sid'
            ? section?.draft[section?.draft?.sectionorder[0]]?.isHeader ||
              propRef?.current?.draft[propRef?.current?.draft?.sectionorder[0]]?.isHeader
            : false
        }
        propRef={propRef}
        wixProductsAndServices={wixProductsAndServices}
        wixSiteProperties={wixSiteProperties}
        fetchMoreWixProductsAndServicesLoading={fetchMoreWixProductsAndServicesLoading}
        fetchMoreWixProductsAndServices={fetchMoreWixProductsAndServices}
        configText={configText}
        openWixPreview={openWixPreview}
        setOpenWixPreview={setOpenWixPreview}
        addItem={addItem}
        {...props}
      />
    );
  }

  // for wix client preview if state is declined then dont show proposal
  if (
    isWix &&
    clientWixPreview &&
    !isOwner &&
    (prop?.state === 'declined' || prop?.state === 'edit')
  ) {
    return (
      <>
        <ProposalHeaderBar />
        <EmptyProposal />
      </>
    );
  }

  if (
    (path === '/pd/:id' ||
      path === '/cd/:cid' ||
      path === '/PDF/:cid' ||
      path === '/pt/:tid' ||
      path === '/ps/:sid' ||
      (wixEditor && openWixPreview) ||
      (templateWixEditor && openWixPreview) ||
      clientWixPreview) &&
    !isOwner
  ) {
    if (
      (path === '/cd/:cid' || clientWixPreview) &&
      (!propRef?.current?.published ||
        propRef?.current?.published === 'expired' ||
        propRef?.current?.state === 'deleted')
    ) {
      return <EmptyProposal status={propRef?.current?.published} />;
    }

    return (
      <Publish
        user={user || userInfo?.fetchUser}
        config={config}
        usersTeam={usersTeam}
        updateProposalClient={updateProposalClient}
        templateWixPreview={templateWixPreview}
        templateUserWixPreview={openWixPreview && templateWixEditor}
        isTemplate={
          path === '/pt/:tid' || commonUtils.getQueryStringValue('type') === 'template'
            ? true
            : false
        }
        isSection={path === '/ps/:sid' ? true : false}
        isHeader={
          path === '/ps/:sid' ? section?.draft[section?.draft?.sectionorder[0]]?.isHeader : false
        }
        proposalSettings={proposalSettings}
        isSavingForm={isSavingForm}
        setIsSavingForm={setIsSavingForm}
        wixPreview={openWixPreview && wixEditor ? true : false}
        addItem={addItem}
        setOpenWixPreview={setOpenWixPreview}
        clientWixPreview={clientWixPreview}
        {...props}
      />
    );
  }

  if ((template && templateWixPreview) || (clientWixPreview && isOwner)) {
    return (
      <PublishContent
        config={config}
        prop={template || prop}
        templateWixPreview={!!template}
        previewTemplate={!!template}
        userWixPreview={!!prop}
      />
    );
  }

  return (
    <Wizard
      saveUser={saveUser}
      prop={propRef.current || prop}
      user={user || userInfo?.fetchUser}
      config={config}
      subscribeToUpdateProposal={subscribeToUpdateProposal}
      wixProductsAndServices={wixProductsAndServices}
      wixSiteProperties={wixSiteProperties}
      setWixSiteProperties={setWixSiteProperties}
      fetchWixProductsAndServicesAndInfo={handleFetchWixProductsAndServicesAndInfo}
      fetchMoreWixProductsAndServicesLoading={fetchMoreWixProductsAndServicesLoading}
      fetchMoreWixProductsAndServices={fetchMoreWixProductsAndServices}
      {...props}
    />
  );
};

const Proposal = (props) => {
  const { path } = props;
  return (
    <ProposalContextProvider
      value={{
        path,
      }}>
      <ProposalContent {...props} />
    </ProposalContextProvider>
  );
};

export default Proposal;
